<template>
  <base-section id="affiliates" class="grey lighten-4" space="36">
    <v-container>
      <base-section-heading :title="title" />

      <confirm-dialog
          :visible="confirmDelete"
          title="Cette action est irreversible"
          message="Ëtes vous sûr?"
          :actions="['Annuler', 'Confirmer']"
          v-on:action="onDialogAction"
      />


      <section v-for="(item, pos) in data" :key="item._id">
        <div v-if="editing === item._id">
          <v-row>
            <v-col cols="11">
              <v-text-field
                label="Question"
                v-model="item.defect"
                hint="Enter the text question"
              />
            </v-col>
            <v-col cols="1">
              <v-btn icon dark small color="red" @click="removeQuestion">
                <font-awesome-icon icon="times" />
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-for="(par, idx) in item.items" :key="idx">
            <v-col offset="1" cols="9">
              <v-text-field
                :label="'Reponse #' + idx "
                v-model="item.items[idx]"
                hint="Saisissez une reponse au probleme"
              />
            </v-col>
            <v-col cols="1">
              <v-btn icon dark small color="red">
                <font-awesome-icon icon="times" />
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col offset="1" cols="9">
              <v-btn text dark small color="primary" @click="addAnswer(pos)">
                Ajouter une réponse
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col offset="9" cols="3">
              <v-btn text dark small color="grey" @click="cancel">
                Annuler
              </v-btn>
              <v-btn text dark small color="green" @click="save(item)">
                Enregistrer
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row>
            <v-col cols="11">
              {{ item.defect }}
            </v-col>
            <v-col cols="1" v-if="editing === null">
              <v-btn
                icon
                dark
                small
                color="primary"
                @click="editing = item._id"
              >
                <font-awesome-icon icon="pen" />
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-for="(par, idx) in item.items" :key="idx">
            <v-col offset="1" cols="9">
              {{ par }}
            </v-col>
          </v-row>
        </div>
        <v-divider />
      </section>
      <v-row v-if="editing === null">
        <v-col offset="0" cols="9">
          <v-btn text dark small color="primary" @click="addQuestion">
            Ajouter une question
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import ConfirmDialog from "@/components/base/ConfirmDialog";
export default {
  name: "QCMEditor",

  components: {ConfirmDialog},

  data: () => ({
    editing: null,
    confirmDelete : false
  }),

  props: {
    title: String,
    data: Array
  },

  methods: {
    removeQuestion() {
      this.confirmDelete = true;
    },
    onDialogAction: function (action) {
      if (action === "Confirmer") {
        this.$emit("update", {
          type: "delete",
          id: this.editing
        });
        this.editing = null;
      }
      this.confirmDelete = false;
    },
    addQuestion() {
      const value = {
        _id: "new",
        lang: "fr",
        defect: "",
        items: []
      };
      this.data.push(value);
      this.editing = value._id;
    },
    addAnswer(pos) {
      console.log(pos);
      this.data[pos].items.push("");

    },
    save(item) {
      item._id = undefined;

      if (this.editing === "new") {
        this.$emit("update", {
          type: "post",
          content: item,
        });
      } else {
        this.$emit("update", {
          type: "put",
          content: item,
          id : this.editing
        });
      }
      this.editing = null;
    },
    cancel() {
      this.$emit("update", {
        type: "read"
      });
      this.editing = null;
    }
  }
};
</script>

<style scoped></style>
